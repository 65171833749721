import ReactDOM from "react-dom";
import React, { useState, useEffect } from "react";
import { useTransition, useTrail } from "react-spring";
import "./styles.css";
import { animated } from "react-spring";

const Logo =
  "https://res.cloudinary.com/dtunq4piy/image/upload/v1624641038/volodya/Volodya_Kurashov_1920x1080_frame/GLAZ_LOGO_FINAL_qqgtkg.png";
//options
const pathCloudinary = "https://res.cloudinary.com/dtunq4piy/image/upload/";
const pathVolodya =
  "/v1616248953/volodya/Volodya_Kurashov_1920x1080_frame/1920x1080/";
const options = "f_auto,q_auto,fl_progressive,dpr_auto";
//page names
const numbers = [
  "white screen",
  "001",
  "002",
  "003",
  "004",
  "005",
  "006",
  "007",
  "008",
  "009",
  "010",
  "011",
  "012",
  "013",
  "014",
  "015",
  "016",
  "017",
  "title"
];
// keyframes array and options [0] - page index, [1] - animation type, [2] - chronométrage
const keyframes = [
  [0, "slow", 1000], //whitescreen
  [1, "slow", 3000],
  [2, "slow", 3000],
  [0, "slow", 1500], //whitescreen
  [3, "slow", 3000], //fast
  [0, "fast", 750], //whitescreen
  [4, "slow", 3000],
  [5, "slow", 3000],
  [0, "slow", 1500], //whitescreen
  [6, "slow", 3000],
  [0, "fast", 750], //whitescreen
  [7, "slow", 3000],
  [8, "slow", 3000],
  [9, "slow", 3000],
  [0, "slow", 1500], //whitescreen
  [10, "slow", 3000], //fast
  [0, "fast", 750], //whitescreen
  [11, "slow", 3000], //fast
  [0, "fast", 750], //whitescreen
  [12, "slow", 3000], //fast
  [0, "fast", 750], //whitescreen
  [13, "slow", 3000], //fast
  [0, "fast", 750], //whitescreen
  [14, "slow", 3000], //fast
  [0, "fast", 750], //whitescreen
  [15, "slow", 3000], //fast
  [0, "fast", 750], //whitescreen
  [16, "slow", 3000],
  [17, "slow", 3000],
  // [0, "fast", 750],
  [18, "slow", 4500], // skip
  [0, "fast", 750]
];

function FetchComponent() {
  const listImages = numbers.map((number) => {
    if (number === "white screen") {
      return <div key={number.toString()}></div>;
    } else if (number === "title") {
      return <div key={number.toString()}></div>;
    } else {
      return (
        <img
          srcSet={`${pathCloudinary}${options},w_480${pathVolodya}${number}.png 480w, 
			 ${pathCloudinary}${options},w_780${pathVolodya}${number}.png 780w,
			 ${pathCloudinary}${options},w_990${pathVolodya}${number}.png 990w,
			 ${pathCloudinary}${options},w_1280${pathVolodya}${number}.png 1280w,
			 ${pathCloudinary}${options},w_1440${pathVolodya}${number}.png 1440w,
			 ${pathCloudinary}${options},w_1920${pathVolodya}${number}.png 1920w
			  `}
          sizes="(max-width: 600px) 480px,
				(max-width: 900px) 780px,
				(max-width: 1100px) 990px,
				(max-width: 1300px) 1280px,
				(max-width: 1900px) 1400px,
				(max-width: 2080px) 1920px,
				"
          src={`${pathCloudinary}${options},w_1920${pathVolodya}${number}.png`}
          alt=""
          className="image"
          style={{ display: "none" }}
          key={number.toString()}
        ></img>
      );
    }
  });
  return <div>{listImages}</div>;
}

const whiteScreen = ({ style }) => (
  <animated.div style={{ ...style }} className="image-container"></animated.div>
);

// setup titles
const titles = [
  <img alt="Volodya Kurashov logo" className="logo" src={Logo} />,
  <p>Contact me</p>,
  <p>
    <a href="tel:+79261673588">+79261673588</a>
  </p>,
  <p>
    <a href="mailto:volodyakurashov@gmail.com">volodyakurashov@gmail.com</a>
  </p>
];

//not used slide
const title = ({ style }) => (
  <animated.div style={{ ...style }} className="image-container">
    {" "}
    <p style={{ textAlign: "center" }}>
      Volodya Kurashov
      <br />
      2008-2021
    </p>
  </animated.div>
);

//map an image page and choose image size

const pages = numbers.map((number) => {
  if (number === "white screen") {
    return whiteScreen;
  } else if (number === "title") {
    return title;
  } else {
    return ({ style }) => (
      <animated.div style={{ ...style }} className="image-container">
        <img
          srcSet={`${pathCloudinary}${options},w_480${pathVolodya}${number}.png 480w, 
			 ${pathCloudinary}${options},w_780${pathVolodya}${number}.png 780w,
			 ${pathCloudinary}${options},w_990${pathVolodya}${number}.png 990w,
			 ${pathCloudinary}${options},w_1280${pathVolodya}${number}.png 1280w,
			 ${pathCloudinary}${options},w_1440${pathVolodya}${number}.png 1440w,
			 ${pathCloudinary}${options},w_1920${pathVolodya}${number}.png 1920w
			  `}
          sizes="(max-width: 600px) 480px,
				(max-width: 900px) 780px,
				(max-width: 1100px) 990px,
				(max-width: 1300px) 1280px,
				(max-width: 1900px) 1400px,
				(max-width: 2080px) 1920px,
				"
          src={`${pathCloudinary}${options},w_1920${pathVolodya}${number}.png`}
          alt=""
          className="image"
        ></img>
      </animated.div>
    );
  }
});

//titles toggle animation

export default function App() {
  //common index state
  const [index, setIndex] = useState(0);
  //state of slideshow animation
  const [animationOn, setAnimation] = useState(true);
  //state of slideshow index
  const [indexPrev, setIndexPrev] = useState(0);
  //are the whole screen clickable?
  const [clickable, setClickable] = useState(true);
  //trail animation state for titles
  const [isHide, setHide] = useState(true);

  //slideshow timer
  useEffect(() => {
    const id =
      animationOn &&
      setTimeout(() => {
        setIndex((c) => (c + 1) % (keyframes.length - 2));
        setIndexPrev((c) => (c + 1) % (keyframes.length - 2));
      }, keyframes[index][2]);
    return () => {
      clearTimeout(id);
    };
  }, [animationOn, index]);

  //double-click prevent timer
  useEffect(() => {
    const click =
      !clickable &&
      setTimeout(() => {
        setClickable(true);
      }, 1000);
    return () => {
      clearTimeout(click);
    };
  }, [clickable]);

  //titles show On \ Off
  const toggleAnimation = () => {
    clickable && setAnimation((c) => !c);
    setHide(!isHide);
    animationOn
      ? setIndex((c) => (c = keyframes.length - 1))
      : setTimeout(() => {
          setIndex(indexPrev);
        }, 750);
    setClickable(false);
  };

  //setup trail animation
  const trail = useTrail(titles.length, {
    opacity: isHide ? 0 : 1,
    delay: isHide ? 0 : 250
  });

  //setup transition
  const transitions = useTransition(index, (p) => p, {
    unique: true,
    config:
      keyframes[index][1] === "slow"
        ? {
            mass: 12.3,
            tension: 35,
            friction: 40,
            clamp: true,
            precision: 0.039,
            duration: 1500
          }
        : {
            mass: 0.1,
            tension: 455,
            friction: 30,
            precision: 0.707,
            velocity: 24
          },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: keyframes[index][1] === "slow" ? { opacity: 0 } : { opacity: 0 }
  });

  // console.log(index, keyframes[index][0]);
  return (
    <div
      className="container"
      onClick={(event) => {
        clickable && toggleAnimation();
      }}
    >
      <div className="image-container">
        <ul>
          {trail.map(({ opacity }, i) => {
            const item = titles[i];
            return (
              <animated.li style={{ opacity }} key={"titles" + i}>
                {item}
              </animated.li>
            );
          })}
        </ul>
      </div>
      {transitions.map(({ item, props, key }) => {
        const Page = pages[keyframes[item][0]];
        return <Page key={"slides" + key} style={props} />;
      })}
    </div>
  );
}

// Hello, explorer
const Glaz = () => {
  console.log(`
	                                                                                               
                          hello, stranger                                                                      
                                                                                                
                                                                                                
                                                                                                
                                                                                                
                                                                                                
                                                                                                
								                  	  @@@               @@@                
                                      .@@#             @@@                    
                    @@*                @@@             @@@                &@@                   
                    .@@@                @@&           @@@                @@@                    
                      @@@@                                             @@@@                     
      @@                @@@        .@@@@@@@@@&.   ,&@@@@@@@@@.        @@@                @@     
      @@@@#                  @@@@@  @@@.                 ,@@@  @@@@@                  @@@@&     
         @@@@           @@@@      @@                         @@      @@@@          ,@@@@        
            @@@     @@@,        @@           ,@@@@@.           @@        /@@@     @@@           
                @@@*           @@        (@@@@@@@@@@@@@,        @@           #@@&               
             @@@              @@        @@@@@@@@@     /@@        @@              @@@            
          @@@                 @*       @@@@@@@@@@     ,@@@       &@                 @@@         
         @@.                 /@       .@@@@@@@@@@@@@@@@@@@       *@                  /@@        
           %@@                @@       @@@@@@@@@@@@@@@@@@@       @@               .@@/          
              #@@/            @@        @@@@@@@@@@@@@@@@%        @#            %@@*             
                  @@@          @@.         @@@@@@@@@@@         *@&          @@@                 
                     .@@@.       @@                           @@       *@@@                     
                          @@@@*    @@,                     (@@    (@@@@                         
                               .@@@@@#@@@              ,@@@#@@@@@.                              
                                        %@@@@@@@@@@@@@@@%                                       
                                                                                                
                                                                                                
                                                                                                
                                                                                                
                                                                                                
                                                                                                
                                                                                                
                                                                                                
                                                                                                
                                                                                                `);
  return <div></div>;
};

ReactDOM.render(
  <React.StrictMode>
    <FetchComponent />
    <App />
    <Glaz />
  </React.StrictMode>,
  document.getElementById("root")
);
